<template>
    <div id="popup">
        <div id="popup-wrapper">
            <div id="popup-message">
                {{ message }}
            </div>

            <div id="popup-buttons">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:  "Popup",
        props: {
            message: {
                required: true,
                type:     String
            },
        }
    }
</script>
