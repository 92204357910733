<template>
    <div id="input-screen">
        <div id="input-guide">
            {{ $t('input.please_give_description') }}
        </div>

        <textarea @input="autoGrow" name="" id="" cols="30" rows="10" :placeholder="$t('generic.start_typing')"
                  v-model="text" autofocus="autofocus"></textarea>

        <div id="recordingWrapper" class="m-t-10"></div>

        <div id="controls">
            <div class="location">
                <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                </div>

                <div class="text">
                    <div v-if="selectedLocation.technology">
                        Aalberts {{ selectedLocation.technology }}
                    </div>
                    <div :class="{'no-technology': !selectedLocation.technology}">
                        {{ selectedLocation.city }}
                    </div>
                </div>
            </div>

            <div class="vertical-centered vertical-centered-remove">
                <div id="removeButton" @click="removeBlob" v-show="blob">
                    <i class="fas fa-times"></i>
                </div>
            </div>

            <div id="recordButton" @click="clickedAction" :class="{recording: isRecording}">
                <i class="fas" :class="[`fa-${isRecording ? 'stop' : 'microphone-alt'}`]"
                   :tap-to-record="$t('input.tap_to_record')" :tap-to-stop="$t('input.tap_to_stop')"></i>

                <div class="dot-1"></div>
                <div class="dot-2"></div>
                <div class="dot-3"></div>
            </div>

            <div class="vertical-centered vertical-centered-send">
                <div id="sendButton" class="skew" @click="sendReport" :disabled="!validData"
                     :class="{disabled: !validData}">
                    <img src="img/action-send.png" alt="">
                    <div class="text">
                        {{ $t('generic.send') }}
                    </div>
                </div>
            </div>
        </div>

        <!--        <popup message="By clicking agree, you consent to the information provided to be used and stored"-->
        <!--               v-show="showPopup">-->
        <!--            <template>-->
        <!--                <div id="agreeButton" class="skew" @click="sendReport">-->
        <!--                    <div class="text">-->
        <!--                        Agree-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </template>-->
        <!--        </popup>-->
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import Popup from "../../components/Popup";

    export default {
        name:       "InputScreen",
        components: {Popup},
        data() {
            return {
                text:         '',
                recordButton: null,
                stopButton:   null,
                pauseButton:  null,
                gumStream:    null,
                rec:          null,
                input:        null,
                audioContext: null,
                recording:    null,
                isRecording:  false,
                blob:         null,

                sendingData:      false,
                showPopup:        false,
                selectedLocation: {}
            }
        },

        computed: {
            validData() {
                let isValid = false;

                if (this.text && this.text.length > 0) {
                    isValid = true;
                }

                if (this.blob) {
                    isValid = true;
                }

                if (this.sendingData) {
                    isValid = false;
                }

                return isValid;
            }
        },

        methods: {
            async showPopupModal() {
                if (this.isRecording) {
                    await this.pauseRecording();
                }

                this.showPopup = true;
            },

            removeBlob() {
                document.querySelector('#recordingWrapper').innerHTML = '';
                this.blob                                             = null;

                if (this.rec) {
                    this.rec.clear();
                }
            },
            autoGrow(event) {
                let element          = event.target;
                element.style.height = "5px";
                element.style.height = (element.scrollHeight) + "px";
            },
            async sendReport() {
                if (!this.validData) {
                    return false;
                }

                this.sendingData = true;

                this.$root.$emit('loading', true);

                if (this.isRecording) {
                    await this.stopRecording();
                }

                let user = JSON.parse(localStorage.getItem('user'));

                let postData = {
                    text:        this.text,
                    first_name:  user.first_name,
                    last_name:   user.last_name,
                    anonymous:   user.anonymous
                                     ? 1
                                     : 0,
                    location_id: JSON.parse(localStorage.getItem('location')).id,
                    category:    JSON.parse(localStorage.getItem('category')).link,
                };

                let headers = {};
                if (!this.blob) {
                    headers['Content-type'] = 'application/json';
                } else {
                    headers['Content-type'] = 'multipart/form-data';

                    let formData = new FormData();
                    formData.append(
                        'audio',
                        this.blob,
                        `SpeakApp-recording-${moment().format('DD-MM-YYYY[-]HH:mm')}.wav`
                    );
                    _.forEach(postData, (value, key) => {
                        formData.append(key, value);
                    });

                    postData = formData;
                }

                let data = await axios({
                    "method": "POST",
                    "url":    "https://speakapp-api.catchmyapp.nl/api/v1/reports",
                    "data":   postData,
                    headers:  headers
                });

                this.$root.$emit('loading', false);

                this.sendingData = false;

                this.pushToRoute(this, {
                    name: 'ThanksScreen',
                });
            },

            goBack() {
                this.$router.back();
            },
            setup() {
                //webkitURL is deprecated but nevertheless
                URL = window.URL || window.webkitURL;
                //MediaStreamAudioSourceNode we'll be recording
                // shim for AudioContext when it's not avb.
                let AudioContext  = window.AudioContext || window.webkitAudioContext;
                this.audioContext = new AudioContext;
            },

            clickedAction() {
                this.isRecording
                    ? this.stopRecording()
                    : this.startRecording();
            },

            startRecording() {
                if (!this.audioContext) {
                    this.setup();
                }

                this.removeBlob();

                let constraints = {
                    audio: true,
                    video: false
                };

                /* We're using the standard promise based getUserMedia()

                https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia */

                navigator.mediaDevices.getUserMedia(constraints)
                    .then(async (stream) => {
                        console.log("getUserMedia() success, stream created, initializing Recorder.js ...");
                        /* assign to gumStream for later use */
                        this.gumStream = stream;
                        /* use the stream */
                        this.input = await this.audioContext.createMediaStreamSource(stream);
                        /* Create the Recorder object and configure to record mono sound (1 channel) Recording 2 channels will double the file size */
                        this.rec = await new Recorder(this.input, {
                            numChannels: 1,
                            type:        'audio/wav',
                        });
                        //start the recording process
                        await this.rec.record()
                        console.log("Recording started");

                        this.isRecording = true;
                    }).catch((err) => {
                    console.warn(err);

                    this.isRecording = false;
                });
            },

            pauseRecording() {
                console.log("pauseButton clicked rec.recording=", this.rec.recording);
                if (this.rec.recording) {
                    //pause
                    this.rec.stop();
                    // this.pauseButton.innerHTML = "Resume";

                    this.isRecording = false;
                } else {
                    //resume
                    this.rec.record()
                    // this.pauseButton.innerHTML = "Pause";

                    this.isRecording = true;
                }
            },

            stopRecording() {
                if (!this.rec) {
                    return false;
                }

                console.log("stopButton clicked");
                // //reset button just in case the recording is stopped while paused
                // this.pauseButton.innerHTML = "Pause";
                //tell the recorder to stop the recording
                this.rec.stop(); //stop microphone access
                this.gumStream.getAudioTracks()[0].stop();
                //create the wav blob and pass it on to createDownloadLink
                this.rec.exportWAV(this.createDownloadLink);

                this.isRecording = false;
            },

            async createDownloadLink(blob) {
                this.blob = blob;

                let url         = URL.createObjectURL(blob);
                let au          = document.createElement('audio');
                let div         = document.createElement('div');
                //add controls to the <audio> element
                au.src          = url;
                au.controls     = true;
                au.controlsList = 'nodownload';
                //add the new audio and a elements to the li element
                div.appendChild(au);
                //add the li element to the ordered list
                document.querySelector('#recordingWrapper').append(div);
            },
        },

        mounted() {
            // Validatie check, zorg ervoor dat alle data ingevuld is
            if (!this.hasLocation()) {
                if (!this.hasUser()) {
                    if (!this.hasCategory()) {
                        this.pushToRoute(this, {
                            name: 'CategoryScreen'
                        });
                    }

                    this.pushToRoute(this, {
                        name: 'NameScreen'
                    });
                }

                this.pushToRoute(this, {
                    name: 'CategoryScreen'
                });
            }

            this.selectedLocation = JSON.parse(localStorage.getItem('location'));

            this.$nextTick(() => {
                document.querySelector('#input-screen textarea').focus();
                window.onload = function () {
                    document.querySelector('#input-screen textarea').focus();
                };

                this.$root.$emit('bottom-border::toggle', false);
            });
        }
    }
</script>
