import Vue from 'vue';
import VueRouter from 'vue-router';
import BusinessIntegrity from '../views/Screens/BusinessIntegrity';
import NameScreen from "../views/Screens/NameScreen";
import InputScreen from "../views/Screens/InputScreen";
import CategoryScreen from "../views/Screens/CategoryScreen";
import LocationScreen from "../views/Screens/LocationScreen";
import ThanksScreen from "../views/Screens/ThanksScreen";
import FeedbackScreen from "../views/Screens/FeedbackScreen";

Vue.use(VueRouter);

/**
 * Hier worden alle routes gedefinieerd
 *
 * De gebruikte metadata is
 * backgroundColor: De achtergrondkleur van de pagina,
 * showTopBar:      Moet de top navigatie getoond worden?,
 * showTopBarLogo:  Moet het logo bovenaan getoond worden?,
 * showBottomBar:   Moet de balk onderaan met het logo getoond worden?,
 * transitionName:  De naam van het transitie effect,
 * routeIndex:      Dit wordt gebruikt om de volgorde van pagina's aan te geven.
 *                  Bij het teruggaan (vorige pagina) wordt naar deze waarde gekeken en op basis hiervan wordt er een effect toegepast.,
 */

const routes = [
    // Het "Home" element
    {
        path:      '/',
        name:      'CategoryScreen',
        component: CategoryScreen,
        meta:      {
            backgroundColor: '#FFFFFF',
            showTopBar:      false,
            showTopBarLogo:  false,
            showBottomBar:   true,
            transitionName:  'slide',
            routeIndex:      0,
        }
    },
    /**
     * Nadat de gebruiker een keuze heeft gemaakt moet de gebruiker kiezen uit een van de volgende opties
     *  1) invoeren voornaam + achternaam
     *  2) aanvinken invoer als anoniem
     */
    {
        path:      '/identity',
        name:      'NameScreen',
        component: NameScreen,
        meta:      {
            backgroundColor: '#110F0F',
            showTopBar:      true,
            showTopBarLogo:  true,
            showBottomBar:   false,
            transitionName:  'slide',
            routeIndex:      1,
        }
    },
    /**
     * Hier kiest de gebruiker voor welke locatie er een melding moet worden aangemaakt
     */
    {
        path:      '/location',
        name:      'LocationScreen',
        component: LocationScreen,
        meta:      {
            backgroundColor: '#FFFFFF',
            showTopBar:      true,
            showTopBarLogo:  true,
            showBottomBar:   false,
            transitionName:  'slide',
            routeIndex:      2,
        }
    },
    /**
     * Hier kan de gebruiker een bericht intypen en/of een geluidsfragment opnemen en opsturen
     */
    {
        path:      '/input',
        name:      'InputScreen',
        component: InputScreen,
        meta:      {
            backgroundColor: '#FFFFFF',
            showTopBar:      true,
            showTopBarLogo:  true,
            showBottomBar:   false,
            transitionName:  'slide',
            routeIndex:      3,
        }
    },
    /**
     * Na de vorige stappen wordt de gebruiker doorgeleid naar deze pagina en vervolgens wordt de gebruiker automatisch teruggestuurd
     */
    {
        path:      '/thanks',
        name:      'ThanksScreen',
        component: ThanksScreen,
        meta:      {
            backgroundColor: '#110F0F',
            showTopBar:      false,
            showTopBarLogo:  false,
            showBottomBar:   false,
            transitionName:  'slide',
            routeIndex:      4,
        }
    },
    {
        path:      '/feedback',
        name:      'FeedbackScreen',
        component: FeedbackScreen,
        meta:      {
            backgroundColor: '#FFFFFF',
            showTopBar:      true,
            showTopBarLogo:  true,
            showBottomBar:   true,
            transitionName:  'slide',
            routeIndex:      9999,
        }
    },
    {
        path:      '/business-integrity',
        name:      'BusinessIntegrity',
        component: BusinessIntegrity,
        meta:      {
            backgroundColor: '#FFFFFF',
            showTopBar:      true,
            showTopBarLogo:  false,
            showBottomBar:   true,
            transitionName:  'slide',
            routeIndex:      9999,
        }
    },
];

const router = new VueRouter({
    mode:   'history',
    base:   process.env.BASE_URL,
    routes: routes,
});

export default router;
