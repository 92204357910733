<template>
    <div id="bottom-border">
        <img src="img/logo-speakapp-dark.png" alt="">
    </div>
</template>

<script>
    export default {
        name: "BottomBorder"
    }
</script>

<style scoped>

</style>