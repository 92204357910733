const mixins = {
    methods: {
        isRunningStandalone() {
            return (window.matchMedia('(display-mode: standalone)').matches);
        },
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/MacIntel/.test(navigator.platform) && !window.MSStream) {
                return "Mac OS X";
            }

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        },

        pushToRoute(VueInstance, route) {
            if (VueInstance.$route.name === route.name) {
                document.location = document.location;

                return;
            }

            return VueInstance.$router
                .push(route)
                .catch(error => {
                    //
                });
        },

        replaceWithRoute(VueInstance, route) {
            if (VueInstance.$route.name === route.name) {
                document.location = document.location;

                return;
            }

            return VueInstance.$router
                .replace(route)
                .catch(error => {
                    //
                });
        },

        hasCategory() {
            let category = localStorage.getItem('category');

            if (category) {
                category = JSON.parse(category);

                if (typeof category === 'object' && category.link) {
                    return true;
                }
            }

            return false;
        },
        hasUser() {
            let user = localStorage.getItem('user');

            if (user) {
                user = JSON.parse(user);

                if (typeof user === 'object') {
                    if (user.first_name) {
                        return true;
                    }

                    if (user.last_name) {
                        return true;
                    }

                    if (user.anonymous) {
                        return true;
                    }
                }
            }

            return false;
        },
        hasLocation() {
            let location = localStorage.getItem('location');

            if (location) {
                location = JSON.parse(location);

                if (typeof location === 'object' && location.id) {
                    return true;
                }
            }

            return false;
        },

        detectMobile() {
            return !!(
                navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
            );
        },
    }
};

export default mixins;
