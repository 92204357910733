<template>
    <div id="category-screen">
        <language-select></language-select>

        <div id="category-title">
            {{ $t('home.welcome') }}
        </div>

        <div id="category-subtitle">
            {{ $t('home.choose') }}
        </div>

        <div id="category-buttons">
            <category-button @click.native="select(category)" :category="category"
                             v-for="category in categories"></category-button>
        </div>

        <div id="feedback-button" @click="showFeedbackScreen">
            <div id="feedback-icon">
                <img src="img/feedback.png" alt="">
            </div>
            <div id="feedback-text">
                {{ $t('home.feedback') }}
            </div>
        </div>
    </div>
</template>

<script>
    import CategoryButton from '../../components/CategoryButton';
    import LanguageSelect from "../../components/LanguageSelect";

    export default {
        name:       "CategoryScreen",
        components: {
            LanguageSelect,
            CategoryButton
        },
        data() {
            return {
                categories: [
                    {
                        text:      this.$t('home.categories.health_and_safety'),
                        icon:      'health_and_safety',
                        link:      'health_and_safety',
                        emitEvent: true,
                        route:     null
                    },
                    {
                        text:      this.$t('home.categories.property_risk'),
                        icon:      'property_risk',
                        link:      'property_risk',
                        emitEvent: true,
                        route:     null
                    },
                    {
                        text:      this.$t('home.categories.sustainability'),
                        icon:      'sustainability',
                        link:      'sustainability',
                        emitEvent: true,
                        route:     null
                    },
                    {
                        text:      this.$t('home.categories.business_integrity'),
                        icon:      'business_integrity',
                        link:      'business_integrity',
                        emitEvent: false,
                        route:     {
                            name: 'BusinessIntegrity'
                        }
                    },
                ]
            }
        },
        methods:    {
            select(Category) {
                if (Category.route) {
                    this.pushToRoute(this, Category.route);
                } else {
                    localStorage.setItem('category', JSON.stringify(Category));

                    this.pushToRoute(this, {
                        name: 'NameScreen'
                    });
                }
            },
            showFeedbackScreen() {
                this.pushToRoute(this, {
                    name: 'FeedbackScreen'
                });
            }
        },
        mounted() {
            this.$nextTick(function () {
                this.$root.$emit('top-bar::toggle', false);
            });
        }
    }
</script>
