<template>
    <div id="splash-screen" :class="{visible: showSplash}">
        <img class="aalberts" src="img/logo-aalberts.png" alt="">
        <img class="speakapp" src="img/logo-speakapp.png" alt="">
    </div>
</template>

<script>
    export default {
        name: "SplashScreen",
        data() {
            return {
                showSplash: true,
            }
        },

        mounted() {
            setTimeout(() => {
                this.$root.$emit('splash::toggle', false);
            }, 1000 * 3);

            this.$root.$on('splash::toggle', (showSplash) => {
                this.showSplash = showSplash;
            });
        }
    }
</script>
