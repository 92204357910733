const de = {
    select_language:    'Select language',
    version:            {
        title:       'Eine neue Version ist verfügbar!',
        version:     'Version',
        description: 'Klicken Sie auf die Schaltfläche "Aktualisieren", um die neueste Version zu verwenden. Dieses Update enthält Verbesserungen, Aktualisierungen und neue Funktionen. Gleichzeitig sind einige Fehler behoben worden.',
        refresh:     'Aktualisieren',
    },
    home:               {
        welcome:    'Willkommen',
        choose:     'Wählen Sie eine Kategorie',
        feedback:   'Rückmeldung',
        categories: {
            health_and_safety:  'Gesundheit und Sicherheit',
            property_risk:      'Immobilienrisiko',
            sustainability:     'Nachhaltigkeit',
            business_integrity: 'Geschäftsintegrität',
        },
    },
    name:               {
        first_name:     'Vorname',
        last_name:      'Nachname',
        do_not_contact: 'Wenden Sie sich nicht an mich, um dieses Problem zu lösen',
    },
    location:           {
        type_location:      "Geben Sie den Standort Ihres Unternehmens ein.",
        no_locations_found: 'Es konnte kein Standort gefunden werden...',
    },
    input:              {
        please_give_description: 'Beschreiben Sie bitte Ihr Problem',
        tap_to_record:           'Zum Aufnehmen antippen',
        tap_to_stop:             'Zum Anhalten antippen',
    },
    feedback:           {
        title: 'Feedback zu der SpeakApp',
    },
    thanks:             {
        thanks_for_your_submission: 'Vielen Dank für die Eingabe!',
        sharing_bad:                "Sharing 'bad' will get us to 'brilliant'",
    },
    business_integrity: {
        redirect_to:  'Sie werden umgeleitet zu...',
        procedure:    'Durchführung SpeakUp!',
        intended_for: 'Die Vorgehensweise gilt nur für...',
        compliance:   'Einhaltung von Gesetzen',
        prevention:   'Betrugsprävention',
        corruption:   'Korruption oder Bestechung',
        conflict:     'Interessenkonflikt',
        accounting:   'Buchhaltung und Berichterstattung',
        insider:      'Interner Handel',
        other:        'Für alle anderen Angelegenheiten wenden Sie sich bitte an Ihren HR-Manager',
    },
    generic:            {
        tap_to_toggle: 'Zum Umschalten antippen',
        next:          'Weiter',
        select:        'Auswählen',
        start_typing:  'Mit der Eingabe beginnen...',
        send:          'Senden',
        yes:           'Ja',
        are_you_sure:  'Sind Sie sicher, dass Sie fortfahren möchten?',
    },
};

export default de;
