<template>
    <label :class="{disabled: disabled}" :data-message="message" class="switch-input">
        <input :checked="rawValue" :disabled="disabled" @change="setValue" type="checkbox">
        <span class="slider round"></span>
    </label>
</template>

<script>
    export default {
        name: 'SwitchInput',

        data() {
            return {
                rawValue: this.value,
            };
        },

        watch: {
            value(newValue) {
                this.rawValue = newValue;
            },
        },

        methods: {
            setValue() {
                this.rawValue = !this.rawValue;

                this.$emit('change', this.rawValue);
            },
        },

        props: {
            value:    {
                required: true,
            },
            message:  {
                required: false,
                type:     String,
            },
            disabled: {
                type:     Boolean,
                required: false,
                default:  false,
            },
        },
    };
</script>
