const en = {
    select_language:    'Select language',
    version:            {
        title:       'A new version is available!',
        version:     'Version',
        description: 'Click the refresh button to use the latest version. This update contains improvements, updates, new functionalities and bug fixes.',
        refresh:     'Refresh',
    },
    home:               {
        welcome:    'Welcome',
        choose:     'Choose your category',
        feedback:   'Feedback',
        categories: {
            health_and_safety:  'Health & Safety',
            property_risk:      'Property Risk',
            sustainability:     'Sustainability',
            business_integrity: 'Business Integrity',
        },
    },
    name:               {
        first_name:     'First Name',
        last_name:      'Last Name',
        do_not_contact: 'Do not contact me to solve this issue',
    },
    location:           {
        type_location:      "Type your company's location",
        no_locations_found: 'No location found...',
    },
    input:              {
        please_give_description: 'Please give a description of your report or comment',
        tap_to_record:           'Tap to record',
        tap_to_stop:             'Tap to stop',
    },
    feedback:           {
        title: 'Feedback about SpeakApp',
    },
    thanks:             {
        thanks_for_your_submission: 'Thank you for your submission!',
        sharing_bad:                "Sharing 'bad' will get us to 'brilliant'",
    },
    business_integrity: {
        redirect_to:  'You will be redirected to',
        procedure:    'SpeakUp! Procedure',
        intended_for: 'This procedure is only intended for',
        compliance:   'compliance with laws',
        prevention:   'prevention of fraud',
        corruption:   'corruption or bribery',
        conflict:     'conflict of interest',
        accounting:   'accounting and reporting',
        insider:      'insider trading',
        other:        'For all other matters please contact your HR manager',
    },
    generic:            {
        tap_to_toggle: 'Tap to toggle',
        next:          'Next',
        select:        'Select',
        start_typing:  'Start typing...',
        send:          'Send',
        yes:           'Yes',
        are_you_sure:  'Are you sure you wish to continue?',
    },
};

export default en;
