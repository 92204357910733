<template>
    <div id="add-to-homescreen" v-if="showInstallMessage" @click="setShown">
        Click here and select "Add to homescreen"
    </div>
</template>

<script>
    export default {
        name:    "AddToHomescreen",
        data() {
            return {
                showInstallMessage: false,
            }
        },
        methods: {
            setShown() {
                localStorage.setItem('shown_ios_popup', true);

                this.showInstallMessage = false;
            }
        },
        mounted() {
            if (!localStorage.getItem('shown_ios_popup')) {
                // Detects if device is on iOS
                const isIos              = () => {
                    const userAgent = window.navigator.userAgent.toLowerCase();
                    return /iphone|ipad|ipod/.test(userAgent);
                }
                // Detects if device is in standalone mode
                const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

                // Checks if should display install popup notification:
                if (isIos() && !isInStandaloneMode()) {
                    this.showInstallMessage = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    #add-to-homescreen {
        position: absolute;
        bottom: 20px;
        text-align: center;
        width: max-content;
        left: 50%;
        transform: translateX(-50%);
        background-color: lightskyblue;
        padding: 10px;
        border: 1px solid lightskyblue;
        border-radius: 15px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-top-color: lightskyblue;
            border-bottom: 0;
            margin-left: -20px;
            margin-bottom: -20px;
        }
    }
</style>
