const pl = {
    select_language:    'Select language',
    version:            {
        title:       'Dostępna jest nowa wersja!',
        version:     'Wersja',
        description: 'Kliknij przycisk odświeżania, aby skorzystać z najnowszej wersji. Ta aktualizacja zawiera ulepszenia, aktualizacje, nowe funkcje i poprawki błędów.',
        refresh:     'Odśwież',
    },
    home:               {
        welcome:    'Witaj',
        choose:     'Wybierz swoją kategorię',
        feedback:   'Informacja zwrotna',
        categories: {
            health_and_safety:  'Zdrowie i bezpieczeństwo',
            property_risk:      'Ryzyko majątkowe',
            sustainability:     'Zrównoważony rozwój',
            business_integrity: 'Uczciwość biznesowa',
        },
    },
    name:               {
        first_name:     'Imię',
        last_name:      'Nazwisko',
        do_not_contact: 'Nie kontaktują się ze mną, żeby rozwiązać tę sprawę',
    },
    location:           {
        type_location:      "Wpisz lokalizację swojej firmy",
        no_locations_found: 'Nie znaleziono żadnej lokalizacji...',
    },
    input:              {
        please_give_description: 'Proszę podać opis swojego raportu lub komentarza',
        tap_to_record:           'Kliknij aby zapisać',
        tap_to_stop:             'Kliknij aby zatrzymać',
    },
    feedback:           {
        title: 'Opinie o SpeakApp',
    },
    thanks:             {
        thanks_for_your_submission: 'Dziękuję za zgłoszenie!',
        sharing_bad:                "Sharing 'bad' will get us to 'brilliant'",
    },
    business_integrity: {
        redirect_to:  'Zostaniesz przekierowany do',
        procedure:    'SpeakUp! Procedura',
        intended_for: 'Procedura ta jest przeznaczona wyłącznie dla',
        compliance:   'zgodność z prawem',
        prevention:   'zapobieganie nadużyciom finansowym',
        corruption:   'korupcja lub łapówkarstwo',
        conflict:     'konflikt interesów',
        accounting:   'rachunkowość i sprawozdawczość',
        insider:      'wykorzystanie informacji poufnych',
        other:        'We wszystkich innych sprawach prosimy o kontakt z kierownikiem działu kadr',
    },
    generic:            {
        tap_to_toggle: 'Kliknij aby przełączyć',
        next:          'Następny',
        select:        'Wybierz',
        start_typing:  'Rozpoczęcie pisania...',
        send:          'Wyślij',
        yes:           'Tak',
        are_you_sure:  'Jesteś pewien, że chcesz kontynuować?',
    },
};

export default pl;
