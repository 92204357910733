const nl = {
    select_language:    'Select language',
    version:            {
        title:       'Een nieuwe versie is beschikbaar!',
        version:     'Versie',
        description: 'Klik op de knop om de laatste versie te gebruiken. Deze update bevat verbeteringen, updates, nieuwe functionaliteiten en herstelfouten.',
        refresh:     'Verversen',
    },
    home:               {
        welcome:    'Welkom',
        choose:     'Kies uw categorie',
        feedback:   'Feedback / Terugkoppeling',
        categories: {
            health_and_safety:  'Gezondheid en veiligheid',
            property_risk:      'Risico aan het pand',
            sustainability:     'Duurzaamheid',
            business_integrity: 'Zakelijke integriteit',
        },
    },
    name:               {
        first_name:     'Voornaam',
        last_name:      'Achternaam',
        do_not_contact: 'Neem geen contact met mij op om dit probleem op te lossen',
    },
    location:           {
        type_location:      "Typ de locatie van het bedrijf waar je werkt",
        no_locations_found: 'Geen locatie gevonden...',
    },
    input:              {
        please_give_description: 'Geef een beschrijving van je melding of opmerking',
        tap_to_record:           'Tik om op te nemen',
        tap_to_stop:             'Tik om te stoppen',
    },
    feedback:           {
        title: 'Terugkoppeling over SpeakApp',
    },
    thanks:             {
        thanks_for_your_submission: 'Bedankt voor uw inzending!',
        sharing_bad:                "Sharing 'bad' will get us to 'brilliant'",
    },
    business_integrity: {
        redirect_to:  'U wordt doorgestuurd naar',
        procedure:    'SpeakUp! Procedure',
        intended_for: 'Deze procedure is alleen bedoeld voor',
        compliance:   'naleving van de wet',
        prevention:   'het voorkomen van fraude',
        corruption:   'corruptie of omkoping',
        conflict:     'belangenverstrengeling',
        accounting:   'boekhouding en reportage',
        insider:      'handelen met voorkennis',
        other:        'Voor alle andere zaken, neem contact op met je HR manager',
    },
    generic:            {
        tap_to_toggle: 'Tik om te switchen',
        next:          'Volgende',
        select:        'Selecteer',
        start_typing:  'Begin met typen...',
        send:          'Verstuur',
        yes:           'Ja',
        are_you_sure:  'Weet u zeker dat u door wilt gaan?',
    },
};

export default nl;
