<template>
    <div id="top-bar">
        <div id="back-button" @click="action">
            <img src="img/action-back.png" alt="">
        </div>

        <img src="img/logo-speakapp-dark.png" alt="" id="logo-speakapp" v-show="showTopBarLogo">

        <div id="spacer"></div>
    </div>
</template>

<script>
    export default {
        name:    "TopBar",
        data() {
            return {
                showTopBarLogo: true
            }
        },
        beforeMount() {
            this.showTopBarLogo = this.$route.meta.showTopBarLogo;
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            goHome() {
                this.$router.replace({
                    name: 'CategoryScreen',
                });
            },
            action() {
                this.goBack();
            }
        }
    }
</script>
