<template>
    <div id="business-integrity">
        <div id="business-integrity-title">
            {{ $t('business_integrity.redirect_to') }}:
            <br>
            {{ $t('business_integrity.procedure') }}
        </div>

        <div class="business-integrity-text">
            {{ $t('business_integrity.intended_for') }}:
        </div>

        <div class="business-integrity-text">
            {{ $t('business_integrity.compliance') }}
        </div>

        <div class="business-integrity-text">
            {{ $t('business_integrity.prevention') }}
        </div>

        <div class="business-integrity-text">
            {{ $t('business_integrity.corruption') }}
        </div>

        <div class="business-integrity-text">
            {{ $t('business_integrity.conflict') }}
        </div>

        <div class="business-integrity-text">
            {{ $t('business_integrity.accounting') }}
        </div>

        <div class="business-integrity-text">
            {{ $t('business_integrity.insider') }}
        </div>

        <div id="business-integrity-subtitle">
            {{ $t('business_integrity.other') }}
        </div>

        <div id="continue">
            {{ $t('generic.are_you_sure') }}

            <div @click="proceed">
                <span class="text">
                    {{ $t('generic.yes') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BusinessIntegrity",

        methods: {
            proceed() {
                function openTab(url) {
                    // Create link in memory
                    let a    = window.document.createElement("a");
                    a.target = '_blank';
                    a.href   = url;

                    // Dispatch fake click
                    let e = window.document.createEvent("MouseEvents");
                    e.initMouseEvent(
                        "click",
                        true,
                        true,
                        window,
                        0,
                        0,
                        0,
                        0,
                        0,
                        false,
                        false,
                        false,
                        false,
                        0,
                        null
                    );
                    a.dispatchEvent(e);
                }

                openTab('https://aalberts.com/speak-up?h=1'); // will open new tab on iPad and new window on iPhone

                this.pushToRoute(this, {
                    name: 'CategoryScreen'
                });
            }
        }
    }
</script>
