<template>
    <div id="feedback-screen">
        <div id="feedback-title">
            {{ $t('feedback.title') }}
        </div>

        <textarea autofocus="autofocus" @input="autoGrow" name="" id="" cols="30" rows="10" v-model="text"></textarea>

        <div id="sendButton" class="skew" @click="sendFeedback" :disabled="!validData" :class="{disabled: !validData}">
            <div class="text">
                {{$t('generic.send')}}
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name:     "FeedbackScreen",
        data() {
            return {
                text: ''
            }
        },
        computed: {
            validData() {
                return this.text.length > 0;
            }
        },
        methods:  {
            autoGrow(event) {
                let element          = event.target;
                element.style.height = "225px";
                element.style.height = (element.scrollHeight) + "px";
            },
            async sendFeedback() {
                if (!this.validData) {
                    return false;
                }

                this.$root.$emit('loading', true);

                let data = await axios({
                    "method": "POST",
                    "url":    "https://speakapp-api.catchmyapp.nl/api/v1/feedback",
                    "data":   {
                        text: this.text
                    },
                });

                this.$root.$emit('loading', false);

                this.pushToRoute(this, {
                    name: 'ThanksScreen'
                });
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$root.$emit('bottom-border::toggle', false);
            });
        }
    }
</script>
