<template>
    <div class="category-button">
        <div class="category-button-icon">
            <img :src="`img/${category.icon}.png`" alt="" v-show="category.icon">
        </div>
        <div class="category-button-text" v-html="category.text"></div>
    </div>
</template>

<script>
    export default {
        name:  "CategoryButton",
        props: {
            category: {
                type:     Object,
                required: true
            }
        }
    }
</script>
