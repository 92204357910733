import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment';
import * as lodash from 'lodash';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import mixins from "./mixins";
import localization from "./localization";
import VueI18n from 'vue-i18n';
// import './registerServiceWorker';

global._ = lodash;
global.localization = localization;

Bugsnag.start({
    apiKey:  'df804563132883652a2400416d9cd72f',
    plugins: [new BugsnagPluginVue(Vue)]
})

Vue.prototype.moment     = moment;
Vue.config.productionTip = false;

Vue.use(VueI18n);

// Bouw applicatie op in async => await om ervoor te zorgen dat de localisatie goed is ingeladen
(async () => {
    // Create VueI18n instance with options
    const i18n = new VueI18n(
        {
            fallbackLocale: localization.defaultLocale,
            locale:         localization.getLocale(),
            messages:       localization.languages,
        },
    );

    Vue.mixin(mixins);

    new Vue(
        {
            router,
            store,
            i18n,
            render: (h) => {
                return h(App);
            },
        },
    )
        .$mount('#app');
})();
