<template>
    <div class="input-wrapper" :class="{disabled: disabled}">
        <label :for="id">
            {{ label }}
        </label>
        <input type="text" :id="id" :value="value" @input="onInput" :autofocus="autofocus"
               :disabled="disabled" :class="{disabled: disabled}">
    </div>
</template>

<script>
    export default {
        name:    "InputWrapper",
        props:   {
            id:        {
                required: true,
                type:     String
            },
            label:     {
                required: true,
                type:     String
            },
            value:     {
                required: true,
                type:     String
            },
            disabled:  {
                required: false,
                type:     Boolean,
                default:  false
            },
            autofocus: {
                required: false,
                type:     Boolean,
                default:  false
            },
        },
        methods: {
            onInput(event) {
                this.$emit('update:value', event.target.value);
                this.$emit('change', event.target.value);
                this.$emit('input', event.target.value);
            }
        }
    }
</script>
