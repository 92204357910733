<template>
    <div id="language-select">
        <div id="open-language-select" @click="showOptions = !showOptions">
            <span class="flag-icon" :class="[`flag-icon-${getLangName(locale)}`]"></span>
            <span class="current-language-text">
                {{ currentLanguageName }}
            </span>
        </div>

        <div id="language-backdrop" @click="showOptions = !showOptions" v-if="showOptions"></div>
        <div id="language-options" v-if="showOptions">
            <h4>
                {{ $t('select_language') }}
            </h4>

            <div class="language-item" :class="{selected: locale === key}" @click="setLanguage(key)" :key="key"
                 v-for="(language, key) in languages">
                <span class="flag-icon" :class="[`flag-icon-${getLangName(key)}`]"></span>
                <span class="language-item-name">
                    {{ language }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LanguageSelect",

    data() {
        return {
            locale:      localization.getLocale(),
            languages:   localization.languageNames,
            showOptions: false,
        };
    },
    computed: {
        currentLanguageName() {
            let name = this.languages[this.locale];

            if (this.detectMobile()) {
                name = _.last(this.locale.split('-')).toUpperCase();
            }

            return name;
        }
    },
    methods:  {
        setLanguage(language) {
            localization.setLocale(language);

            this.showOptions = false;

            setTimeout(() => {
                window.location = window.location;
            }, 100);
        },
        getLangName(language) {
            language = language.split('-')[0];

            if (language === 'en') {
                language = 'gb';
            }

            return language;
        },
    }
}
</script>
