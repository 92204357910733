const fr = {
    select_language:    'Select language',
    version:            {
        title:       'Une nouvelle version est disponible!',
        version:     'Version',
        description: 'Cliquez sur le bouton de rafraîchissement pour utiliser la dernière version. Cette mise à jour contient des améliorations, des mises à jour, de nouvelles fonctionnalités et des corrections de bugs.',
        refresh:     'Rafraîchir',
    },
    home:               {
        welcome:    'Bienvenue',
        choose:     'Choisissez votre categorie',
        feedback:   'Retour d\'information',
        categories: {
            health_and_safety:  'Santé et Securité',
            property_risk:      'risques immobiliers',
            sustainability:     'Durabilité',
            business_integrity: 'integrité de l\'entreprise',
        },
    },
    name:               {
        first_name:     'Prénom',
        last_name:      'Nom de famille',
        do_not_contact: 'Ne me contactez pas pour résoudre ce problème',
    },
    location:           {
        type_location:      "Renseignez la localisation de votre entreprise",
        no_locations_found: 'Aucune localisation trouvée...',
    },
    input:              {
        please_give_description: 'Veuillez décrire votre rapport ou commentaire',
        tap_to_record:           'Appuyez pour enregistrer',
        tap_to_stop:             'Appuyez pour arrêter',
    },
    feedback:           {
        title: 'Retour d\'information de SpeakApp',
    },
    thanks:             {
        thanks_for_your_submission: 'Merci pour votre contribution!',
        sharing_bad:                "Sharing 'bad' will get us to 'brilliant'",
    },
    business_integrity: {
        redirect_to:  'Vous serez redirigé vers',
        procedure:    'La procédure SpeakUp!',
        intended_for: 'Cette procédure est seulement destinée à',
        compliance:   'Conformité avec la loi',
        prevention:   'La prévention de fraude',
        corruption:   'corruption ou les pots-de -vin',
        conflict:     'conflit d\'intérêt',
        accounting:   'comptabilité et rapport',
        insider:      'délit d\'initié',
        other:        'pour toutes autres questions, veuillez contacter votre responsable HR local',
    },
    generic:            {
        tap_to_toggle: 'Appuyez pour changer',
        next:          'Suivant(e)',
        select:        'Choisir',
        start_typing:  'Commencer à écrire...',
        send:          'Envoyer',
        yes:           'Oui',
        are_you_sure:  'Êtes-vous sûr de vouloir continuer?',
    },
};

export default fr;
