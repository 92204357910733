<template>
    <div id="version-screen" v-if="(hasNewVersion && version)">
        <div id="version-wrapper">
            <h3 id="version-title">
                {{ $t('version.title') }}
            </h3>

            <div id="version-header">
                <small id="version-readable">
                    <b>{{ $t('version.version') }}:</b> {{ version.readable }}
                </small>

                <small id="version-created_at">
                    {{ moment(version.created_at).format('DD-MM-YYYY HH:mm') }}
                </small>
            </div>

            <div id="version-description">
                {{ $t('version.description') }}
            </div>

            <div id="refreshButton" class="skew" @click="refreshApp">
                <div class="text">
                    {{ $t('version.refresh') }}
                </div>
            </div>

            <div id="version-backdrop">
                <img class="aalberts" src="img/logo-aalberts.png" alt="">
                <img class="speakapp" src="img/logo-speakapp.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:    "VersionScreen",
        data() {
            return {
                version:       null,
                hasNewVersion: false,
            }
        },
        methods: {
            refreshApp() {
                localStorage.setItem('version', this.version.id);
                localStorage.setItem('version_json', JSON.stringify(this.version));

                document.location = document.location;
            }
        },
        mounted() {
            this.$root.$on('version::outdated', (version) => {
                this.version       = version;
                this.hasNewVersion = true;
            });
        }
    }
</script>
