<template>
    <div id="location-screen">
        <div id="inputs">
            <input-wrapper id="name" :label="$t('location.type_location')" @input="isTyping = true"
                           :value.sync="locationName" :autofocus="true"></input-wrapper>
        </div>

        <div id="locations-list">
            <label :for="`location-${location.id}`" class="location" @click="selectLocation(location)"
                   v-for="location in locations">
                <div class="select">
                    <input type="radio" name="location" :id="`location-${location.id}`"
                           :checked="isSelected(location)">
                    <span class="checkmark"></span>
                </div>

                <div class="info">
                    <div v-if="location.technology">
                        Aalberts {{ location.technology }}
                    </div>
                    <div>
                        {{ location.street }}
                    </div>
                    <div>
                        {{ location.city }}
                    </div>
                    <div>
                        {{ location.country }}
                    </div>
                </div>
            </label>

            <div id="no-location-found" v-if="locationName && !locations.length">
                {{ $t('location.no_locations_found') }}
            </div>
        </div>

        <div id="continue" :class="{disabled: !validData}">
            <div @click="next">
                <span class="text">
                    {{ $t('generic.select') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import InputWrapper from "../../components/InputWrapper";
    import SwitchInput from "../../components/SwitchInput";
    import axios from 'axios';
    import * as _ from 'lodash';

    export default {
        name:       "LocationScreen",
        components: {
            SwitchInput,
            InputWrapper
        },
        watch:      {
            locationName: _.debounce(function () {
                this.isTyping = false;
            }, 500),
            isTyping:     function (value) {
                if (!value) {
                    this.getLocations();
                }
            },
        },
        data() {
            return {
                isTyping: false,

                selectedLocation: null,
                locations:        [],
                locationName:     ''
            }
        },
        computed:   {
            validData() {
                return !!this.selectedLocation;
            }
        },
        methods:    {
            isSelected(location) {
                if (!this.selectedLocation) {
                    return false;
                }

                return this.selectedLocation.id === location.id;
            },
            selectLocation(location) {
                this.selectedLocation = location;
            },
            async getLocations() {
                if (!this.locationName || !this.locationName.length) {
                    this.locations = [];
                    return;
                }

                this.$root.$emit('loading', true);

                let data = await axios({
                    "method": "GET",
                    "url":    "https://speakapp-api.catchmyapp.nl/api/v1/locations",
                    "params": {
                        "per_page": "10",
                        "only":     "[\"id\", \"technology\", \"name\", \"street\", \"city\", \"country\"]",
                        "q":        this.locationName
                    }
                });

                this.locations = data.data.data;

                this.$root.$emit('loading', false);
            },
            next() {
                if (!this.validData) {
                    return false;
                }

                let location = this.selectedLocation;

                localStorage.setItem('location', JSON.stringify(location));

                this.pushToRoute(this, {
                    name: 'InputScreen'
                });
            }
        },

        mounted() {
            // Validatie check, zorg ervoor dat alle data ingevuld is
            if (!this.hasUser()) {
                if (!this.hasCategory()) {
                    this.pushToRoute(this, {
                        name: 'CategoryScreen'
                    });
                }

                this.pushToRoute(this, {
                    name: 'NameScreen'
                });
            }

            let location = localStorage.getItem('location');
            if (location) {
                this.selectedLocation = JSON.parse(location);

                if (this.selectedLocation && this.selectedLocation.city) {
                    this.locationName = this.selectedLocation.city;

                    this.getLocations();
                }
            }

            this.$nextTick(() => {
                this.$root.$emit('bottom-border::toggle', false);
            });
        }
    }
</script>
