<template>
    <div id="name-screen">
        <img src="img/logo-aalberts.png" alt="" id="logo-aalberts">

        <div id="inputs">
            <input-wrapper id="first_name" :label="$t('name.first_name')" :value.sync="user.first_name"
                           :disabled="user.anonymous"></input-wrapper>

            <input-wrapper id="last_name" :label="$t('name.last_name')" :value.sync="user.last_name"
                           :disabled="user.anonymous"></input-wrapper>

            <div class="input-wrapper m-t-30">
                <label for="anonymous" class="text-center">
                    {{ $t('name.do_not_contact') }}
                </label>
                <switch-input id="anonymous" @change="user.anonymous = $event" :value="user.anonymous"
                              :message="$t('generic.tap_to_toggle')"></switch-input>
            </div>
        </div>

        <div id="continue" :class="{disabled: !validData}">
            <div @click="next">
                <span class="text">
                    {{ $t('generic.next') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import InputWrapper from "../../components/InputWrapper";
    import SwitchInput from "../../components/SwitchInput";

    export default {
        name:       "NameScreen",
        components: {
            SwitchInput,
            InputWrapper
        },
        data() {
            return {
                user: {
                    first_name: '',
                    last_name:  '',
                    anonymous:  false
                }
            }
        },
        watch:      {
            'user.anonymous'(newValue) {
                if (newValue) {
                    this.user.first_name = '';
                    this.user.last_name  = '';
                }
            }
        },
        computed:   {
            validData() {
                let valid = !(!this.user.first_name && !this.user.last_name);

                if (!valid) {
                    valid = this.user.anonymous;
                }

                return valid;
            }
        },
        methods:    {
            next() {
                if (!this.validData) {
                    return false;
                }

                let user = this.user;

                if (user.anonymous) {
                    user.first_name = '';
                    user.last_name  = '';
                }

                localStorage.setItem('user', JSON.stringify(user));

                this.pushToRoute(this, {
                    name: 'LocationScreen'
                });
            }
        },

        mounted() {
            // Validatie check, zorg ervoor dat alle data ingevuld is
            if (!this.hasCategory()) {
                this.pushToRoute(this, {
                    name: 'CategoryScreen'
                });
            }

            let user = localStorage.getItem('user');
            if (user) {
                this.user = JSON.parse(user);
            }

            this.$nextTick(() => {
                this.$root.$emit('bottom-border::toggle', false);
            });
        }
    }
</script>
