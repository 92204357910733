import en from './languages/en';
import nl from "./languages/nl";
import fr from "./languages/fr";
import pl from "./languages/pl";
import de from "./languages/de";

let languages     = {
    'en-EN': en,
    'nl-NL': nl,
    'de-DE': de,
    'fr-FR': fr,
    'pl-PL': pl,
};
let languageNames = {
    'en-EN': 'English',
    'nl-NL': 'Nederlands',
    'de-DE': 'Deutsch',
    'fr-FR': 'Français',
    'pl-PL': 'Polskie',
};

function getCurrentLanguage() {
    let languageToUse;
    let currentLanguage = localStorage.getItem('locale');
    let browserLanguage = navigator.language;

    if (currentLanguage) {
        if (currentLanguage.length === 2) {
            currentLanguage = `${currentLanguage}-${currentLanguage.toUpperCase()}`;
        }

        if (!languages[currentLanguage]) {
            currentLanguage = null;
        } else {
            languageToUse = currentLanguage;
        }
    }

    if (!languageToUse) {
        if (browserLanguage.length === 2) {
            browserLanguage = `${browserLanguage}-${browserLanguage.toUpperCase()}`;
        }

        if (!languages[browserLanguage]) {
            browserLanguage = null;
        } else {
            languageToUse = browserLanguage;
        }
    }

    return languageToUse || 'en-EN';
}

const localization = {
    defaultLocale: 'en-EN',
    locale:        getCurrentLanguage(),
    languages:     languages,
    languageNames: languageNames,
    setLocale(locale) {
        if (!locale) {
            locale = this.defaultLocale;
        }

        localStorage.setItem('locale', locale);

        this.locale = locale;
    },
    getLocale() {
        let locale = getCurrentLanguage();
        if (!locale) {
            locale = this.defaultLocale;
        }

        localStorage.setItem('locale', locale);

        return locale;
    },
    getLocalisedMessages() {
        return this.languages[this.getLocale()];
    },
    getLanguageNames() {
        return languageNames;
    },
    getIcon(locale) {
        let icon = locale.split('-')[1].toLowerCase();

        if (icon === 'en') {
            icon = 'gb';
        }

        return icon;
    },
};

export default localization;

