<template>
    <div id="loader">
        <div id="loader-spinner">
            <div class="arc"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>
