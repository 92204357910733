<template>
    <div id="thanks-screen">
        <img src="img/logo-aalberts.png" alt="" id="logo-aalberts">

        <div class="thanks-text">
            {{ $t('thanks.thanks_for_your_submission') }}
            <br>
            <br>
            {{ $t('thanks.sharing_bad') }}
        </div>

        <i class="fas fa-thumbs-up"></i>

        <img src="img/logo-speakapp.png" alt="" id="logo-speakapp">
    </div>
</template>

<script>
    export default {
        name: "ThanksScreen",
        mounted() {
            this.$root.$emit('bottom-border::toggle', false);

            setTimeout(() => {
                localStorage.removeItem('category');

                this.$router.replace({
                    name: 'CategoryScreen'
                });
            }, 3000);
        }
    }
</script>
