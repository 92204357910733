<template>
    <div id="app" :class="[os, locale, {'page-has-bottom-bar': showBottomBar, 'standalone': isStandalone}]">
        <top-bar v-show="showTopBar"></top-bar>

        <transition :name="transitionName" mode="out-in">
            <router-view/>
        </transition>

        <bottom-border v-show="showBottomBar"></bottom-border>

        <!--        <add-to-homescreen></add-to-homescreen>-->

        <version-screen></version-screen>

        <loader v-show="isLoading"></loader>

        <splash-screen></splash-screen>
    </div>
</template>

<script>
    import SplashScreen from "./views/SplashScreen";
    import BottomBorder from "./components/BottomBorder";
    import moment from "moment";
    import AddToHomescreen from "./components/AddToHomescreen";
    import Loader from "./components/Loader";
    import TopBar from "./components/TopBar";
    import VersionScreen from "./views/Screens/VersionScreen";
    import axios from "axios";

    const DEFAULT_TRANSITION = 'fade';

    export default {
        name:       'App',
        created() {
            this.$router.beforeEach((to, from, next) => {
                let transitionName = to.meta.transitionName || from.meta.transitionName;

                if (transitionName === 'slide') {
                    const toDepth   = to.meta.routeIndex;
                    const fromDepth = from.meta.routeIndex;
                    transitionName  = toDepth < fromDepth
                        ? 'slide-right'
                        : 'slide-left';
                }

                this.transitionName = transitionName || DEFAULT_TRANSITION;

                if (to.meta.backgroundColor) {
                    document.body.style.backgroundColor = to.meta.backgroundColor;
                }

                this.showBottomBar = to.meta.showBottomBar;
                this.showTopBar    = to.meta.showTopBar;

                next();
            });
        },
        components: {
            VersionScreen,
            TopBar,
            Loader,
            AddToHomescreen,
            BottomBorder,
            SplashScreen
        },
        data() {
            return {
                locale:             null,
                os:             null,
                isStandalone:   false,
                isLoading:      false,
                refreshing:     false,
                registration:   null,
                updateExists:   false,
                showTopBar:     true,
                showBottomBar:  true,
                transitionName: DEFAULT_TRANSITION,
            }
        },
        watch:      {
            '$route': {
                handler: function (newValue) {
                    document.body.style.backgroundColor = newValue.meta.backgroundColor;
                    this.showBottomBar                  = newValue.meta.showBottomBar;
                    this.showTopBar                     = newValue.meta.showTopBar;
                }
            },
        },

        mounted() {
            this.getLatestVersion();
            this.setLocale();

            let os            = this.getMobileOperatingSystem()
                .toLowerCase()
                .split(' ')
                .join('_');
            this.isStandalone = this.isRunningStandalone();
            this.os           = os;

            document.querySelector('html').classList.add(os);
            if (this.isStandalone) {
                document.querySelector('html').classList.add('standalone');
            }

            this.$root.$on('bottom-border::toggle', (visible) => {
                this.showBottomBar = visible;
            });

            this.$root.$on('top-bar::toggle', (visible) => {
                this.showTopBar = visible;
            });

            this.$root.$on('loading', (isLoading) => {
                this.isLoading = isLoading;
            });

            document.body.style.backgroundColor = this.$route.meta.backgroundColor;
            this.showBottomBar                  = this.$route.meta.showBottomBar;
            this.showTopBar                     = this.$route.meta.showTopBar;

            // Events to trigger setting last activity
            _.forEach([
                'onkeypress',
                'onmousedown',
                'onmouseup',
                'onmousemove',
                'ontouchmove',
                'ontouchstart',
                'ontouchend',
                'onscroll',
            ], event => {
                document.addEventListener(event, () => {
                    localStorage.setItem('last_activity', moment().format('DD-MM-YYYY HH:mm:ss'));
                });
            });

            document.addEventListener('touchmove', function (event) {
                event = event.originalEvent || event;
                event.preventDefault();
            }, false);

            localStorage.setItem('last_activity', moment().format('DD-MM-YYYY HH:mm:ss'));
        },
        methods: {
            setLocale() {
                this.locale = _.last(localization.getLocale().split('-')).toLowerCase();
            },
            async getLatestVersion() {
                let data = await axios({
                    "method": "GET",
                    "url":    "https://speakapp-api.catchmyapp.nl/api/v1/versions",
                    "params": {
                        "per_page":        "1",
                        "only":            "[\"id\", \"description\", \"title\", \"readable\", \"created_at\"]",
                        'order_column':    'updated_at',
                        'order-direction': 'DESC',
                    }
                });

                if (data && data.data && data.data.data && data.data.data.length === 1) {
                    let latestVersion = data.data.data[0];

                    let currentVersion = localStorage.getItem('version');

                    if (currentVersion && latestVersion.id > currentVersion) {
                        this.$root.$emit('version::outdated', latestVersion);
                    } else if (latestVersion && latestVersion.id) {
                        localStorage.setItem('version', latestVersion.id);
                        localStorage.setItem('version_json', JSON.stringify(latestVersion));
                    }
                }

            }
        },
    }
</script>

<style lang="scss">
    @import "styles/build";

    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition-duration: 0.5s;
        transition-property: height, opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
        overflow: hidden;
    }

    .slide-left-enter,
    .slide-right-leave-active {
        opacity: 0;
        transform: translate(2em, 0);
    }

    .slide-left-leave-active,
    .slide-right-enter {
        opacity: 0;
        transform: translate(-2em, 0);
    }
</style>
